import axios from 'axios';
import { APIResponse, LoginSource } from '@arema/components/Classes';
import { AdminLogin, ExternalLogin } from './AuthClasses';
export const ROOT_URL = process.env.REACT_APP_API_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_ROOT = process.env.REACT_APP_CDN_ROOT;
export const DEBUG = process.env.REACT_APP_DEBUG=='1';
export const CAPTCHA_ACTIVE = process.env.REACT_APP_CAPTCHA_ACTIVE=='1';
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA;

async function post<T=any>(endpoint: string, data?: any, headers?: any) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'Content-Type': 'application/json',
			...headers
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/auth/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
}

var loginAdmin = async (username: string, password: string, source: LoginSource, captcha: string, remember: boolean=false, code: string, pdv_token: string=null)=>{
	var res = await post<AdminLogin>('admin/login', { username, password, source, remember, captcha, code }, pdv_token ? { 'pdv-token': pdv_token } : null);
	if(!res.error){
		window.localStorage.setItem('arema-auth-admin', JSON.stringify(res.data));
		setTimeout(()=>{
			window.dispatchEvent(new Event('arema-auth-admin-login'));
		}, 50);
	}
	return res;
}

var loginExternal = async (email: string, password: string, captcha: string, remember: boolean=false)=>{
	var res = await post<ExternalLogin>('external/login', { email, password, captcha, remember });
	if(!res.error){
		window.localStorage.setItem('arema-auth-external', JSON.stringify(res.data));
		setTimeout(()=>{
			window.dispatchEvent(new Event('arema-auth-external-login'));
		}, 50);
	}
	return res;
}

var loginRefreshAdmin = async (refresh: string)=>{
	return post<AdminLogin>('admin/refresh', { refresh });
}

var loginRefreshExternal = async (refresh: string)=>{
	return post<ExternalLogin>('external/refresh', { refresh });
}

var sendAdminForgot = (email: string, captcha: string)=>{
	return post<boolean>('admin/forgot', { email, captcha })
}

var sendExternalForgot = (email: string, captcha: string)=>{
	return post<boolean>('external/forgot', { email, captcha })
}

export default {
	loginAdmin,
	loginExternal,
	loginRefreshAdmin,
	loginRefreshExternal,
	sendAdminForgot,
	sendExternalForgot,
}